<template>
<div class="my-4">
  <b-container>
    <b-row class="mb-4" v-if="!loadingNews">
      <b-col>
        <b-card>
          <publication
            :propItem="item"
            :hideLink="true"
            :key="item.id"
            :tagsProp=tags
            :username="user.username"
            :permissionAddArticle="permissionAddArticle"
            :permissionAddRisk="permissionAddRisk"
            :permissionAddTag="permissionAddTag"
            :permissionInsights="permissionInsights"
            :permissionDeletePublication = "permissionDeletePublication"
            :permissionReadTagsPanel="permissionReadTagsPanel"
             />
        </b-card>
      </b-col>
    </b-row>
    <b-row v-if="loadingNews" class="text-center mb-4" align-h="center">
      <b-col cols="1">
        <div><i class="fa fa-spinner fa-pulse fa-lg fa-fw"></i></div>
      </b-col>
    </b-row>
    <b-row class="mb-4" v-if="!loadingRelated">
      <b-col class="text-white bg-kpmg-blue py-4 m-4">
        <h5 class="card-title font-kpmg font-size-44">More from {{item.publisher}}</h5>
        <ul>
          <li v-for="item in related.recentByPublisher" :key="'startnews' + item.id" class="text-white">
            <a :href="item.link" target="_blank"><i class="fa fa-external-link" aria-hidden="true" style="color: white;"></i></a>&nbsp;
            <router-link class="text-white" :to="{ name: 'NewsItem', params: { id: item.id}}" >{{item.name}} <small> - {{item.date}} </small></router-link>
          </li>
        </ul>
      </b-col>
      <b-col class="text-white bg-kpmg-dark-blue py-4 m-4" v-if="relatedTopics">
        <h5 class="card-title font-kpmg font-size-44">More on related topics</h5>
        <div v-for="(regtopicName, index) in regTopics" :key="index" class="mb-2">
          <h5> {{ regtopicName }} </h5>
          <ul>
            <li v-for="(headline, innerIndex) in relatedByTopic(regtopicName)" :key="innerIndex">
              <a :href="item.link" target="_blank"><i class="fa fa-external-link" aria-hidden="false" style="color: white;"></i></a>&nbsp;
              <router-link class="text-white" :to="{ name: 'NewsItem', params: { id: headline.id}}" >{{headline.name}} <small>  - {{headline.date}} - {{headline.publisher}}</small></router-link>
            </li>
          </ul>
        </div>
      </b-col>
    </b-row>
    <b-row v-if="loadingRelated" class="text-center mb-4" align-h="center">
      <b-col cols="1">
        <div><i class="fa fa-spinner fa-pulse fa-lg fa-fw"></i></div>
      </b-col>
    </b-row>
  </b-container>
</div>
</template>

<script>
import _ from 'lodash'
import ac from '../libs/accesscontrol'
import Publication from '@/components/PublicationNew'

export default {
  components: {
    Publication
  },
  computed: {
    user: {
      get () {
        return this.$store.state.user
      }
    },
    regTopics () {
      const topics = new Set()
      _.each(this.related["relatedByTopic"], topic => { topics.add(topic.regtopicName) })
      return Array.from(topics)
    }
  },
  created: async function () {
    this.permissionAddArticle = ac.can(this.user.acgroups).createAny('article').granted
    this.permissionAddRisk = ac.can(this.user.acgroups).createAny('marrisk').granted
    this.permissionAddTag = ac.can(this.user.acgroups).createAny('newstag').granted
    this.permissionDeletePublication = ac.can(this.user.acgroups).deleteAny('publication').granted
    this.permissionInsights = true
    this.permissionReadAnalytics = ac.can(this.user.acgroups).readAny('analytics').granted
    this.permissionReadTagsPanel = ac.can(this.user.acgroups).readAny('tagspanel').granted
    if (this.$config.BUILD === 'external') {
      this.permissionAddRisk = false
      this.permissionAddTag = false
      this.permissionDeletePublication = false
      this.permissionInsights = ac.can(this.user.acgroups).readAny('article').granted
      this.permissionReadAnalytics = false
      this.permissionReadTagsPanel = false
    }
    try {
      let apiName = 'cosmos'
      let path = `/tags`
      this.tags = await this.$Amplify.API.get(apiName, path)
    } catch (e) {
      this.$logger.warn('error getting events for a day', e)
    }
    this.firstLoad()
  },
  data () {
    return {
      authToken: '',
      formEventValidated: false,
      gridLoaderColor: 'black',
      gridLoaderSize: '10px',
      item: {},
      loadingNews: true,
      loadingRelated: true,
      permissionAddRisk: false,
      permissionReadAnalytics: false,
      permissionReadTagsPanel: false,
      related: {},
      relatedTopics: false,
      tags: []
    }
  },
  methods: {
    relatedByTopic (regTopic) {
      return this.related["relatedByTopic"]
        .filter(item => item.regtopicName === regTopic)
    },
    firstLoad: async function () {
      this.$logger.debug('first load started')
      try {
        let apiName = 'cosmos'
        let path = `/news/item/${this.$route.params.id}`
        let response = await this.$Amplify.API.get(apiName, path)
        this.$logger.debug('firstLoad:')
        this.$logger.debug(response)
        this.item = response
        document.title = "KPMG Regulatory Horizon: " + this.item.name
        this.loadingNews = false
        this.$stat.log({ page: 'news item', action: 'open news item', model: 'publication', model_id: this.$route.params.id })
        this.relatedItems()
      } catch (e) {
        this.$logger.warn('saved ERROR: ' + e)
      }
    },
    relatedItems: async function () {
      try {
        this.related = ""
        this.loadingRelated = true
        this.relatedTopics = false
        let results = 5
        let topicsList = []
        _.each(this.item.regtopics, item => {
          topicsList.push(item.id)
        })
        let params = {
          body: {
            publisher: this.item.publisher,
            topics: topicsList
          }
        }
        let apiName = 'cosmos'
        let path = `/news/item/related/${this.item.id}/${results}`
        let response = await this.$Amplify.API.post(apiName, path, params)
        this.$logger.debug('relatedItems:')
        this.$logger.debug(response)
        this.related = response
        this.loadingRelated = false
        if (topicsList.length !== 0) {
          this.relatedTopics = true
        }
      } catch (e) {
        this.$logger.warn('saved ERROR: ' + e)
      }
    }
  },
  watch: {
    '$route' (to, from) {
      this.firstLoad()
    }
  }
}
</script>

<style>
</style>
